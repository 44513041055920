<template>
  <div>
    <div>
      <div class="opSearchRow">
        <svg class="icon opChooseList2" aria-hidden="true" @click="closeWebview()" v-if="!device">
          <use xlink:href="#iconfanhui" />
        </svg>
        <span v-if="device" class="f16"  @click="closeWebview()">
          返回
        </span>
        <SearchRow @search-call="pagingbyzone(true)" width="1.85rem" placeholder="供应商名称" v-model="searchName"></SearchRow>
      </div>
      <div>
        <ScrollBox class="opOrderListScroll" @on-top="scrollTop" @on-bottom="scrollBottom" :loadType="loadType">
          <template v-slot:scrollBox>
            <div v-if="dataList.length" class="supplierCon">
              <ul>
                <li v-for="(item, index) in dataList" :key="index" @click="goDetail(item, index)">
                  <img :src="item.thumbnail" v-if="item.thumbnail" />
                  <img src="../../assets/images/icar.png" v-if="!item.thumbnail" />
                  <div class="suppDetali">
                    <p>{{ item.name }}</p>
                    <span>
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icongps-outline" />
                      </svg>
                      {{ item.address }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <Skeleton v-if="isFirst" :skList="skList" />
            <NoData v-if="!dataList.length && loadType == '' && !isFirst"></NoData>
          </template>
        </ScrollBox>
      </div>
    </div>
    <supplierDetail ref="supplierDetail" :justView="true"></supplierDetail>
  </div>
</template>
<script>
import supplierDetail from '@/views/common/tenant/supplierDetail';
import { device } from '@/views/utils/common';
export default {
  data() {
    return {
      skList: [
        {
          width: '65%',
          row: 5
        },
        {
          width: '20%',
          position: 'right'
        },
        {
          width: '40%',
          inline: true
        },
        {
          width: '50%'
        },
        {
          width: '30%',
          ml: '.1rem'
        },
        {
          width: '15%',
          inline: true
        },
        {
          width: '100%',
          inline: true
        },
        {
          width: '0',
          inline: true
        }
      ],
      isFirst: true,
      total: 0,
      loadType: '',
      isShowDetail: false,
      flag: null,
      isOnlode: false,
      allLoaded: false,
      pageIndex: 1,
      pageSize: 10,
      dataList: [],
      zonesearch: {},
      searchName: '',
      choiseItem: {}
    };
  },
  components: {
    supplierDetail
  },
  created() {
    console.log('created===');
    this.redirectRouteListeners(); //记录全局路由轨迹
    this.$root.$eventHub.$on('closeWebview', data => {
      this.closeWebview();
    });
    this.device = device();
    let eventData = this.$cookies.get('eventData') || this.SmartStorage.get('eventData');
    let paramCode = this.SmartStorage.get('paramCode');
    console.log(eventData);
    this.zonesearch = {
      city: eventData.cityDictVal ? eventData.cityDictVal : eventData.city || paramCode.city
    };
    if (eventData.province || paramCode.province) {
      this.zonesearch.province = eventData.province || paramCode.province;
    }
    this.pagingbyzone();
  },
  methods: {
    scrollTop(filter) {
      this.loadType = 'top';
      this.pageIndex = 1;
      if (filter) {
        this.dataList = [];
      }
      this.pagingbyzone(true);
    },
    scrollBottom() {
      if (this.pageSize * this.pageIndex >= this.total) {
        this.loadType = 'none';
      } else {
        this.loadType = 'bottom';
        this.pageIndex += 1;
        this.pagingbyzone();
      }
    },
    redirectRouteListeners() {
      this.SmartStorage.set('routeListeners', ['closeWebview']);
    },
    // 退出城市车队页面
    closeWebview() {
      const container = this.$cookies.get('container');
      switch (container) {
        case 'browser':
          this._browserBack();
          break;
        case 'h5':
          this._h5Back();
          break;
        case 'native':
        default:
          this.closeAppWithReload();
      }
    },
    closeDetail() {
      this.isShowDetail = false;
    },
    goDetail(item) {
      let params = {
        supplierId: item.supplierId,
        city: this.zonesearch.city
      };
      this.$refs.supplierDetail.showPopup(item, params);
    },
    searchSupplier(item, index) {
      this.choiseItem = item;
      this.flag = index;
      this.isShowDetail = false;
    },
    //获取车队订单列表
    async pagingbyzone(clear) {
      let params = {
        search: { name: this.searchName },
        zonesearch: this.zonesearch,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        tenantId: this.$cookies.get('tenant_id')
      };
      this.allLoaded = false;
      let _this = this;
      this.services.pagingbyzone(params).then(function(res) {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.dataList = [];
          }
          let eventList = res.content.rows;
          for (let i = 0; i < eventList.length; i++) {
            if (eventList[i].name.indexOf('测试') > -1) {
              console.log(eventList[i]);
            } else {
              _this.dataList.push(eventList[i]);
            }
          }
          _this.total = res.content.total;
          _this.loadType = '';
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/tenant/item.scss';
/deep/.searchRowInput {
  input {
    // width: 100%!important;
    border-radius: 0.04rem;
    padding-right: 0;
  }
}
/deep/.searchRowContent {
  width: 80%;
  input{
    float: unset;
  }
}
/deep/.searchRowDeleteSvg{
  position: fixed;
  right: 0.3rem;
  top: .34rem;
}
</style>
